import React from "react"
import {
  Box,
  Flex,
  Grid,
  Text,
  Heading,
  Container,
  Image,
} from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "../link"

const Logo = ({ sponsor }) => (
  <Box>
    {sponsor.image.gatsbyImageData ? (
      <GatsbyImage image={sponsor.image.gatsbyImageData} alt="" />
    ) : (
      <Image src={sponsor.image.url} />
    )}
  </Box>
)

const EventPartnerTiersCollectioin = ({ block, primaryColor }) => {
  const { title, subtitle, tiers } = block
  return (
    <Container sx={{ my: [7, 7, 9] }}>
      <Grid columns={[1, 1, 1, "1fr 4fr"]} gap={[16, 16, 16, 256]}>
        <Flex
          sx={{
            mb: [0],
            flexDirection: "column",
          }}
        >
          <Heading
            as="h3"
            variant="h2"
            sx={{
              fontWeight: 400,
              fontSize: [6, 7, 7, 7],
              mb: [0, 4],
              mt: [0],
              color: primaryColor.hex,
            }}
          >
            {title}
          </Heading>
          <Box
            sx={{ a: { color: primaryColor.hex } }}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </Flex>
        <Box>
          {tiers.map(tier => (
            <Box
              sx={{
                borderTop: "1px solid",
                borderColor: "dark",
                mb: [6],
              }}
            >
              <Text
                as="p"
                sx={{
                  fontFamily: "alternative",
                  textTransform: "uppercase",
                  mt: [1, 2, 2],
                  mb: [4],
                }}
              >
                {tier.title}
              </Text>
              {tier.partners.length > 0 && (
                <Flex>
                  {tier.partners.map(sponsor => (
                    <Box key={sponsor.id} sx={{ mr: [4] }}>
                      {sponsor.url ? (
                        <OutboundLink href={sponsor.url} target="blank">
                          <Logo sponsor={sponsor} />
                        </OutboundLink>
                      ) : (
                        <Logo sponsor={sponsor} />
                      )}
                    </Box>
                  ))}
                </Flex>
              )}
            </Box>
          ))}
        </Box>
      </Grid>
    </Container>
  )
}

export default EventPartnerTiersCollectioin
