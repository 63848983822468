import React from "react"
import { Box, Flex, Grid, Heading, Link } from "@theme-ui/components"

const EventCta = ({
  block,
  registrationLink,
  registrationCta,
  primaryColor,
}) => {
  const { title } = block
  return (
    <Flex
      sx={{
        mb: [0],
        py: [7, 7, 9],
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: primaryColor.hex,
      }}
    >
      <Heading
        as="h3"
        variant="h2"
        sx={{
          fontWeight: 500,
          fontSize: [6, 7, 7, 7],
          color: "light",
          textAlign: "center",
          mb: [0,5],
          mt: [0],
        }}
      >
        {title}
      </Heading>
      {registrationCta && registrationLink && (
        <Link
          href={registrationLink}
          variant="button"
          target="_blank"
          sx={{
            mt: [4, 5],
            display: "inline-block",
            backgroundColor: "dark",
            color: primaryColor.hex,
          }}
        >
          {registrationCta}
        </Link>
      )}
    </Flex>
  )
}

export default EventCta
