import React from "react"
import { graphql } from "gatsby"
import { Box, Container } from "@theme-ui/components"
import Layout from "../components/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import Breadcrumbs from "../components/breadcrumbs"
import CustomPinnedVideo from "../components/blocks/customPinnedVideo"
import { getEventPath } from "../utils/path"
import EventMap from "../components/blocks/eventMap"
import RichContentStructuredText from "../components/richContentStructuredText"
import EventHero from "../components/eventHero"
import EventCountdown from "../components/blocks/eventCountdown"
import EventAgenda from "../components/blocks/eventAgenda"
import EventCta from "../components/blocks/eventCta"
import EventPartnerTiersCollectioin from "../components/blocks/eventPartnerTiersCollection"
import EventSpeakersCollection from "../components/blocks/eventSpeakersCollection"

const Event = ({ data: { page, site } }) => {
  // console.log("EVENT", page)
  const favicon = useFavicon().site.faviconMetaTags
  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getEventPath(page),
    }
  })

  return (
    <Layout pageSlug={"home"} locale={"en"} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Container sx={{ pb: [0, 0] }}>
        <Box sx={{ py: [4, 4, 6], pt: [10, 10, 6] }}>
          <Breadcrumbs page={page} />
        </Box>
        <EventHero page={page} />
      </Container>
      <Container
        variant="md"
        sx={{
          my: [6, 7, 8],
          p: {
            fontSize: [4, 5, 5],
            strong: { fontWeight: 500 },
          },
          h3: {
            color: "dark",
            fontWeight: 400,
            fontSize: [5, 6, 7],
            strong: {
              color: page.primaryColor.hex,
              fontWeight: 400,
            },
          },
        }}
      >
        <RichContentStructuredText text={page.body} />
      </Container>
      {page.content.map(block => (
        <Box as="section" key={block.id}>
          {block.model.apiKey === "custom_pinned_video" && (
            <CustomPinnedVideo block={block} color={page.primaryColor.hex} />
          )}
          {block.model.apiKey === "event_map" && <EventMap block={block} />}
          {block.model.apiKey === "event_countdown" && (
            <Container variant="md">
              <EventCountdown
                block={block}
                registrationCta={page.registrationCta}
                registrationLink={page.registrationLink}
                primaryColor={page.primaryColor}
              />
            </Container>
          )}
          {block.model.apiKey === "event_agenda" && (
            <EventAgenda
              block={block}
              registrationCta={page.registrationCta}
              registrationLink={page.registrationLink}
              primaryColor={page.primaryColor}
            />
          )}
          {block.model.apiKey === "event_cta" && (
            <EventCta
              block={block}
              registrationCta={page.registrationCta}
              registrationLink={page.registrationLink}
              primaryColor={page.primaryColor}
            />
          )}
          {block.model.apiKey === "event_partner_tiers_collection" && (
            <EventPartnerTiersCollectioin
              block={block}
              primaryColor={page.primaryColor}
            />
          )}
          {block.model.apiKey === "event_speakers_collection" && (
            <EventSpeakersCollection
              block={block}
              primaryColor={page.primaryColor}
            />
          )}
        </Box>
      ))}
    </Layout>
  )
}

export default Event

export const query = graphql`
  query EventQuery($id: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsEvent(id: { eq: $id }) {
      title
      date
      location
      registrationCta
      registrationLink
      body {
        value
      }
      heroImage {
        gatsbyImageData(width: 1480, placeholder: BLURRED)
      }
      logo {
        url
      }
      text {
        url
      }
      primaryColor {
        hex
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      model {
        apiKey
      }
      content {
        ... on DatoCmsEventSpeakersCollection {
          id
          title
          subtitle
          speakers {
            id
            name
            jobTitle
            company
            thumbnail {
              gatsbyImageData(
                placeholder: BLURRED
                height: 360
                imgixParams: { ar: "16:9", fit: "crop" }
              )
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCustomPinnedVideo {
          description
          video
          image {
            gatsbyImageData(placeholder: BLURRED, height: 1000)
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsEventMap {
          id
          title
          embed
          model {
            apiKey
          }
        }
        ... on DatoCmsEventPartnerTiersCollection {
          id
          title
          subtitle
          tiers {
            id
            title
            partners {
              id
              url
              name
              image {
                gatsbyImageData(height: 90, placeholder: BLURRED)
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsEventAgenda {
          id
          title
          sessions {
            title
            sessions {
              description
              id
            }
            id
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsEventCountdown {
          id
          title
          date
          model {
            apiKey
          }
        }
        ... on DatoCmsEventCta {
          id
          title
          model {
            apiKey
          }
        }
      }
    }
  }
`
