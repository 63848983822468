import React from "react"
import { Box, Heading, Text, Flex, Link } from "@theme-ui/components"
import Countdown from "react-countdown"

// Random component
const Completionist = () => <span></span>

const ClockItem = ({ time, label }) => (
  <Flex
    sx={{
      px: [3, 5, 9],
      py: [5],
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <Text sx={{ fontSize: [7, 8], fontWeight: 500 }}>{time}</Text>
    <Text
      sx={{
        fontSize: [2, 3],
        textTransform: "uppercase",
        color: "#909090",
        fontWeight: 500,
      }}
    >
      {label}
    </Text>
  </Flex>
)

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />
  } else {
    // Render a countdown
    return (
      <Flex
        sx={{
          backgroundColor: "#292929",
          color: "#EBEBEB",
          borderRadius: "30px",
          justifyContent: "space-between",
          width: "100%",
          px: [3],
        }}
      >
        <ClockItem time={days} label="días" />
        <ClockItem time={hours} label="horas" />
        <ClockItem time={minutes} label="minutos" />
        <ClockItem time={seconds} label="segundos" />
      </Flex>
    )
  }
}

const EventCountdown = ({
  block,
  registrationCta,
  registrationLink,
  primaryColor,
}) => {
  const { title, date } = block
  return (
    <Flex
      sx={{
        mb: [8, 9],
        p: { m: [0] },
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Heading
        as="h3"
        variant="h1"
        sx={{
          fontSize: [6, 7, 7, 7],
          fontWeight: 400,
          color: "dark",
          mb: [7],
          textAlign: "center",
        }}
      >
        {title}
      </Heading>
      <Countdown date={date} renderer={renderer} />
      {registrationCta && registrationLink && (
        <Link
          href={registrationLink}
          variant="button"
          target="_blank"
          sx={{
            mt: [4, 5],
            display: "inline-block",
            backgroundColor: primaryColor.hex,
          }}
        >
          {registrationCta}
        </Link>
      )}
    </Flex>
  )
}

export default EventCountdown
