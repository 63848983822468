import React from "react"
import { Box, Flex, Grid } from "@theme-ui/components"
import VideoEmbed from "./embed"
import { GatsbyImage } from "gatsby-plugin-image"

const CustomPinnedVideo = ({ block, color }) => {
  const { description, video, image } = block
  return (
    <Box
      sx={{
        position: "relative",
        mb: [6, 9],
      }}
    >
      <Grid
        columns={["1fr", "1fr", "1fr", ".5fr .5fr"]}
        sx={{ backgroundColor: color ? color : "primary" }}
        gap={[0]}
      >
        <Flex
          sx={{
            gridRow: [2, 2, 2, 1],
            px: [3, 3, 3, 7],
            pr: [3, 3, 9, 9],
            py: [6, 6, 6, 7],
            pb: [7, 7, 7, 8],
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Flex
            sx={{
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              as="div"
              sx={{
                color: "light",
                my: [0, 0, 0, 0],
                fontSize: [2, 5],
                my: [0],
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Flex>
        </Flex>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: video && "dark",
            gridRow: [1, 1, 1, 1],
            iframe: {
              width: "100%",
              height: "auto",
            },
          }}
        >
          {image && !video ? (
            <Box
              sx={{
                ".gatsby-image-wrapper": {
                  height: "100%",
                },
              }}
            >
              <GatsbyImage image={image.gatsbyImageData} alt="" />
            </Box>
          ) : (
            <VideoEmbed link={video} fullWidth={true} />
          )}
        </Box>
      </Grid>
    </Box>
  )
}

export default CustomPinnedVideo
