import React from "react"
import { Box, Flex, Grid } from "@theme-ui/components"
import VideoEmbed from "./embed"
import { GatsbyImage } from "gatsby-plugin-image"

const EventMap = ({ block }) => {
  const { title, embed } = block
  return (
    <Box sx={{ p: { m: [0] } }}>
      <Box dangerouslySetInnerHTML={{ __html: embed }} />
    </Box>
  )
}

export default EventMap
